<template>
  <label class="relative inline-flex items-center cursor-pointer">
    <input
      type="radio"
      :name="name"
      :checked="checked"
      @input="$emit('input', $event)"
      @change="$emit('change', $event)"
    />
    <span class="checkbox--controls">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="12" cy="12" r="10" fill="#1DA1F2" />
        <circle cx="12" cy="12" r="4" fill="white" />
      </svg>
    </span>
    <span v-if="label" class="ml-16 rtl:ml-0 rtl:mr-16">{{ label }}</span>
  </label>
</template>

<script>
export default {
  emits: ["input", "change"],
  props: {
    name: {
      type: String,
      required: true,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
  },
};
</script>

<style scoped>
input[type="radio"] {
  width: 18px;
  height: 18px;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

input[type="radio"] + .checkbox--controls {
  width: 18px;
  height: 18px;
  @apply relative flex items-center justify-center border-2 rounded-full border-base-65;
}

input[type="radio"]:checked + .checkbox--controls {
  @apply bg-blue border-blue;
}

input[type="radio"] + .checkbox--controls > svg {
  opacity: 0;
}

input[type="radio"]:checked + .checkbox--controls > svg {
  opacity: 1;
}
</style>
